<template>
  <v-row>
    <v-col cols="12">
      <v-form ref="form">
        <base-card>
          <v-card-title>Registrar Clientes FE</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  ref="NombreCompania"
                  v-model="dataClient.NombreCompania"
                  label="Cliente"
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-select
                  ref="Estado"
                  v-model="dataClient.Estado"
                  :items="typeEnvironment"
                  item-text="textEnvironment"
                  item-value="valueEnvironment"
                  label="Ambiente"
                  prepend-inner-icon="mdi-sitemap"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-select
                  ref="RefProveedor"
                  v-model="dataClient.Proveedor"
                  :items="listTechnologyProvider"
                  item-text="textEnvironment"
                  item-value="valueEnvironment"
                  label="Proveedor tecnológico"
                  prepend-inner-icon="mdi-file-chart"
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  ref="Url"
                  v-model="dataClient.Url"
                  label="Url Proveedor tecnológico"
                  prepend-inner-icon="mdi-account-network"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  ref="TokenEnterprise"
                  v-model="dataClient.TokenEnterprise"
                  v-validate-role="permisosRoles"
                  label="Token Enterprise"
                  prepend-inner-icon="mdi-account-key"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                  :disabled="isPrividerEDN"
                  required
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  ref="TokenPassword"
                  v-model="dataClient.TokenPassword"
                  label="Token Password"
                  prepend-inner-icon="mdi-key"
                  clearable
                  :rules="formRules"
                  :error-messages="errorMessages"
                  :disabled="isPrividerEDN"
                  required
                />
              </v-col>
            </v-row>

            <v-divider class="mt-4" v-if="isPrividerEDN"></v-divider>
              <v-col  cols="12" sm="12" v-if="isPrividerEDN">
                <additional-data-client
                ref="AdditionalDataClientForm"
                @update:grant_type="updateGrantType"
                @update:client_id="updateClientId"
                @update:client_secret="updateClientSecret"
                @update:scope="updateScope"
                @update:url_token="updateUrlToken"
                @update:validate_forms="updateValidateFormAddData"
                @update:emisor_client_Id="updateEmisor"
                @update:representation_graphic="updateRepresentation"
                />
              </v-col>

            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  ref="NitCliente"
                  v-model="dataClient.NitCliente"
                  label="Nit"
                  prepend-inner-icon="mdi-pencil-box-outline"
                  type="number"
                  clearable
                  :rules="nitFormRules"
                  :error-messages="errorMessages"
                  required
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  ref="EmailFE"
                  v-model="dataClient.EmailFE"
                  label="Email envío FE no procesadas"
                  prepend-inner-icon="mdi-email"
                  clearable
                  :rules="emailRules"
                  required
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  ref="EmailDelivery"
                  v-model="dataClient.EmailDelivery"
                  label="Email delivery"
                  prepend-inner-icon="mdi-email"
                  clearable
                  :rules="emailDeliveryRules"
                />
              </v-col>            
            </v-row>

            <v-row>
              <v-col cols="12" sm="12">
                <v-btn
                  class="ma-2"
                  style="
                    background-color: rgb(204 33 40);
                    color: white !important;
                  "
                  @click="validateClient"
                >
                  Registrar
                </v-btn>
                <v-btn
                  class="ma-2"
                  color="GRAY"
                  @click="limpiarFormulario"
                >
                  Limpiar
                </v-btn>
              </v-col>
            </v-row>
              <v-alert v-show="successAlert" :type="alertType" text>
                {{ msgAlert }}
              </v-alert>
          </v-card-text>
        </base-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import {
  listEnvironment,
  listTechnologyProvider,
  listAPIbyProvider
} from "@/data/listEnvironment";

import { v4 as uuidv4 } from 'uuid';

export default {
  name: "RegistrarClients",
  components: {
    AdditionalDataClient: () => import("./AdditionalDataClient"),
  },
  data() {
    return {
      permisosRoles: ["administrador"],
      dataClient: {Proveedor:"1"},
      AddDataCliente: {
        grant_type: '',
        client_id: '',
        client_secret: '',
        scope: '',
        url_token: '',
        emisor_client_Id: '',
        representation_graphic: '',
      },
      Url: "",
      tokenEnterprise: "",
      TokenPassword: "",
      NombreCompania: "",
      EmailFE: "",
      EmailDelivery: "",
      Estado: "",
      NitCliente: '',
      typeEnvironment: listEnvironment,
      listTechnologyProvider: listTechnologyProvider,
      listAPIbyProvider: listAPIbyProvider,
      errorMessages: "",
      isValidateFormAddData:false,
      formHasErrors: null,
      successAlert: false,
      alertType:"success",
      msgAlert: "",
      formRules: [(v) => !!v || "Este campo es requerido"],
      nitFormRules: [
        (v) => !!v || "Ingresa el NIT sin digito de verificación",
        (v) => v > 0 || "Este campo no acepta números negativos",
      ],
      emailRules: [
          (v) => !!v || 'Este campo es requerido',
          (v) => /.+@.+\..+/.test(v) || 'Correo inválido',
        ],
        emailDeliveryRules: [
        (v) => !v || /.+@.+\..+/.test(v) || "Correo inválido",
      ],
    };
  },
  computed: {
    form() {
      return {
        NombreCompania: this.dataClient.NombreCompania,
        Url: this.dataClient.Url,
        Estado: this.dataClient.Estado,
        TokenEnterprise: this.dataClient.TokenEnterprise,
        TokenPassword: this.dataClient.TokenPassword,
        EmailFE: this.dataClient.EmailFE,
        EmailDelivery: this.dataClient.EmailDelivery,
      };
    },
    statusResponseClient() {
      return this.$store.state.clients.statusResponse;
    },
    statusResponseClientMsg() {
      return this.$store.state.clients.statusResponseMsg;
    },
    isPrividerEDN() {
      let idEDN = this.listTechnologyProvider.filter((item)=> item.textEnvironment==this.getNameEDN())[0].valueEnvironment;
      return this.dataClient.Proveedor == idEDN;
    }
  },
  watch: {
    'dataClient.Proveedor': function(newValue, oldValue) {
      if(!newValue) return false;
      this.dataClient.Url = this.getUrlProveedor();
      if(this.isPrividerEDN){
      }else{
        this.limpiarAddcliente();
        this.dataClient.TokenEnterprise="";
        this.dataClient.TokenPassword="";

      }
    },
    'dataClient.Estado': function(newValue, oldValue) {
      if(!newValue) return false;
      this.dataClient.Url = this.getUrlProveedor();
    },
    formHasErrors() {
      if (this.formHasErrors === false) {
        //this.sendClient();
      }
      this.formHasErrors = null;
    },
    statusResponseClient() {
      if (this.statusResponseClient === false) {
        this.generateAlert('success', this.statusResponseClientMsg);
        this.formHasErrors = true;
        this.$store.dispatch("clients/setStatusResponse", -1);
      }else if(this.statusResponseClient === true){
        this.generateAlert('error', this.statusResponseClientMsg);
        this.$store.dispatch("clients/setStatusResponse", -1);
      }
    }
  },
  methods: {
    generateAlert(typeAlert, msj) {
      this.successAlert = true;
      this.msgAlert= msj;
      this.alertType= typeAlert;
      if(typeAlert == 'success'){
        this.$store.dispatch("clients/getListClients");
        this.clearForm();
      }

      setTimeout(() => {
        this.successAlert = false;
      }, 3000);
    },
    clearForm() {
      this.$refs.form.reset();
    },
    validateClient() {

      if(this.isPrividerEDN) this.$refs.AdditionalDataClientForm.validateForm();

      this.formHasErrors = !this.$refs.form.validate();

      if(this.formHasErrors === false) this.sendClient();

    },limpiarFormulario() {
      this.limpiarAddcliente();
      this.clearForm();
    },
    limpiarAddcliente() {
        this.AddDataCliente.client_id="";
        this.AddDataCliente.client_secret="";
        this.AddDataCliente.scope="";
        this.AddDataCliente.grant_type="";
        this.AddDataCliente.url_token="";
        this.AddDataCliente.emisor_client_Id="";
        this.AddDataCliente.representation_graphic="";
    },
    sendClient() {
      this.dataClient.addData = "";
      if(this.isPrividerEDN){
        this.dataClient.addData= JSON.stringify(this.AddDataCliente)
      }

      if(this.isPrividerEDN && !this.isValidateFormAddData) return false;
      this.$store.dispatch("clients/insertClients", {
        ...this.dataClient,
      });
    },
    updateGrantType(newValue) {
      this.AddDataCliente.grant_type = newValue;
    },
    updateClientId(newValue) {
      this.AddDataCliente.client_id = newValue;
      this.$set(this.dataClient, 'TokenEnterprise', "EDN_TE-" + newValue);
    },
    updateClientSecret(newValue) {
      this.AddDataCliente.client_secret = newValue;
      this.$set(this.dataClient, 'TokenPassword', "EDN_TP-" + newValue);
    },
    updateScope(newValue) {
      this.AddDataCliente.scope = newValue;
    },
    updateUrlToken(newValue) {
      this.AddDataCliente.url_token = newValue;
    },
    updateValidateFormAddData(newValue) {
      this.isValidateFormAddData = newValue;
    },
    updateEmisor(newValue) {
      this.AddDataCliente.emisor_client_Id = newValue;
    },
    updateRepresentation(newValue) {
      this.AddDataCliente.representation_graphic = newValue;
    },
    getUrlProveedor(){
      let estado = this.dataClient.Estado;
      let urlDocumento = "";
      if(!estado) estado = "P";
      if(this.isPrividerEDN){
        let urlsEDN = this.listAPIbyProvider.filter((item)=> item.provider==this.getNameEDN() && item.environment == estado)[0].urls;
        urlDocumento =  urlsEDN.filter((item)=> item.type == "document")[0].url;
      }else{
        let urlsHKA = this.listAPIbyProvider.filter((item)=> item.provider==this.getNameHKA() && item.environment == estado)[0].urls;
        urlDocumento =  urlsHKA.filter((item)=> item.type == "document")[0].url;
      }

      return urlDocumento;
    },
    getNameEDN(){
      return "EDN";
    },
    getNameHKA(){
      return "HKA";
    }
  },
};
</script>
