var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-toolbar',{staticClass:"red-title",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Sincronización por rangos - "+_vm._s(_vm.nombreCompania)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Rango de fechas","prepend-icon":"mdi-calendar","readonly":"","required":"","error":_vm.dateRangeError,"error-messages":_vm.dateRangeErrorMessage,"rules":[_vm.validationRules.required]},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":"","min":_vm.minDate,"max":_vm.maxDate,"error":_vm.dateRangeError,"error-messages":_vm.dateRangeErrorMessage},on:{"change":_vm.validateDateRange},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{staticStyle:{"background-color":"rgb(204, 33, 40)","color":"white"},attrs:{"text":""},on:{"click":_vm.onCancel}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticStyle:{"background-color":"rgb(204, 33, 40)","color":"white"},attrs:{"text":"","disabled":_vm.dateRangeError},on:{"click":_vm.onAccept}},[_vm._v(" Aceptar ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"label":"Serie","prepend-icon":"mdi-text-box","required":"","error":!!_vm.fieldErrors.serie,"error-messages":_vm.fieldErrors.serie,"rules":[
                _vm.validationRules.required,
                _vm.validationRules.alphanumeric,
              ]},model:{value:(_vm.serie),callback:function ($$v) {_vm.serie=$$v},expression:"serie"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"section-title"},[_vm._v("Rangos de documentos")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Rango de documentos (Inicio)")]),_c('v-text-field',{attrs:{"prepend-icon":"mdi-file-document","type":"number","required":"","error":!!_vm.fieldErrors.documentRangeStart,"error-messages":_vm.fieldErrors.documentRangeStart,"rules":[
                _vm.validationRules.rangeStartEnd,
                _vm.validationRules.rangeDocument,
              ]},on:{"blur":function($event){_vm.documentRangeStart = Number(_vm.documentRangeStart)}},model:{value:(_vm.documentRangeStart),callback:function ($$v) {_vm.documentRangeStart=$$v},expression:"documentRangeStart"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Rango de documentos (Final)")]),_c('v-text-field',{attrs:{"prepend-icon":"mdi-file-document","type":"number","required":"","error":!!_vm.fieldErrors.documentRangeEnd,"error-messages":_vm.fieldErrors.documentRangeEnd,"rules":[
                _vm.validationRules.rangeStartEnd,
                _vm.validationRules.rangeStartBeforeEndDocument,
              ]},on:{"blur":function($event){_vm.documentRangeEnd = Number(_vm.documentRangeEnd)}},model:{value:(_vm.documentRangeEnd),callback:function ($$v) {_vm.documentRangeEnd=$$v},expression:"documentRangeEnd"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"section-title"},[_vm._v("Rangos de resolución")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Rango de resolución (Inicio)")]),_c('v-text-field',{attrs:{"prepend-icon":"mdi-note-edit-outline","type":"number","required":"","error":!!_vm.fieldErrors.resolutionRangeStart,"error-messages":_vm.fieldErrors.resolutionRangeStart,"rules":[_vm.validationRules.rangeStartEnd]},on:{"blur":function($event){_vm.resolutionRangeStart = Number(_vm.resolutionRangeStart)}},model:{value:(_vm.resolutionRangeStart),callback:function ($$v) {_vm.resolutionRangeStart=$$v},expression:"resolutionRangeStart"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"mr-2"},[_vm._v("Rango de resolución (Final)")]),_c('v-text-field',{attrs:{"prepend-icon":"mdi-note-edit-outline","type":"number","required":"","error":!!_vm.fieldErrors.resolutionRangeEnd,"error-messages":_vm.fieldErrors.resolutionRangeEnd,"rules":[
                _vm.validationRules.rangeStartEnd,
                _vm.validationRules.rangeStartBeforeEnd,
              ]},on:{"blur":function($event){_vm.resolutionRangeEnd = Number(_vm.resolutionRangeEnd)}},model:{value:(_vm.resolutionRangeEnd),callback:function ($$v) {_vm.resolutionRangeEnd=$$v},expression:"resolutionRangeEnd"}})],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticStyle:{"background-color":"rgb(204, 33, 40)","color":"white"},attrs:{"text":"","disabled":_vm.generating},on:{"click":_vm.synchronize}},[_vm._v(" Sincronizar ")]),_c('v-btn',{attrs:{"text":"","color":"gray","disabled":_vm.generating},on:{"click":_vm.clearForm}},[_vm._v(" Limpiar ")])],1)],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"1000"},model:{value:(_vm.responseModal.show),callback:function ($$v) {_vm.$set(_vm.responseModal, "show", $$v)},expression:"responseModal.show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v(" Sincronización de la factura - Detalles ")]),_c('v-card-text',[_c('v-row',{staticClass:"fill-height",attrs:{"align-content":"center","justify":"center"}},[_c('div',{staticClass:"text-h6 pa-4",staticStyle:{"width":"90%","max-height":"400px","overflow-y":"auto"}},[(_vm.responseModal.data)?_c('json-viewer',{attrs:{"value":_vm.responseModal.data,"copyable":"true"},scopedSlots:_vm._u([{key:"copy",fn:function(){return [_vm._v(" Copiar "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]},proxy:true}],null,false,1782964403)}):_vm._e()],1)])],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeResponseModal}},[_vm._v("Cerrar")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }