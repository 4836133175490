<template>
  <v-col cols="12">
    <v-form ref="billingDateForm">
      <base-card>
        <v-card-title>
          Fecha de procesamiento de facturas
        </v-card-title>
        <v-card-text>
          <v-row v-if="isAdmin">
            <v-col cols="12" sm="4">
              <v-select
                ref="cliente"
                v-model="data.IdCompania"
                :items="detailClients"
                item-text="text"
                item-value="IdCompania"
                label="Compañia"
                prepend-inner-icon="mdi-sitemap"
                :error="companySelectorError"
                :rules="[validateCompanyField]"
                :error-messages="helperSelectorText"
                @change="handleSelectChange"
                filter
                search-input
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-text-field
                          class="search-field"
                          v-model="search"
                          placeholder="Buscar"
                          prepend-inner-icon="mdi-magnify"
                          hide-details
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-btn
                class="ma-2"
                style="background-color:rgb(204 33 40);color:white !important"
                @click="checkCompanyField"
              >
                Buscar
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="searchIfIsAdmin">
            <v-col
              cols="12"
              sm="4"
            >
              <v-switch
                v-model="data.configStatus"
                :label="`${data.configStatus ? '(Activo)' : '(Inactivo)'} Cambio de fecha automático`"
                hide-details
                inset
              />
            </v-col>

            <v-col
              cols="12"
              sm="4"
            >
              <v-text-field
                ref="billingDateConfigDays"
                v-model="data.billingDateConfigDays"
                label="Días de antiguedad afectados por el cambio de fecha"
                prepend-inner-icon="mdi-calendar-clock"
                type="number"
                clearable
                :rules="formRules"
                :error-messages="errorMessages"
              />
            </v-col>
            <v-col
              cols="12"
              sm="2"
            >
              <v-btn
                class="ma-2"
                style="background-color:rgb(204 33 40);color:white !important"
                @click="validateConfigData"
              >
                Guardar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-alert
              v-show="successAlert"
              type="success"
              text
            >
              {{ msgSuccessAlert }}
            </v-alert>

            <v-alert
              v-show="errorAlert"
              type="warning"
              text
            >
              {{ msgErrorAlert }}
            </v-alert>
          </v-row>
        </v-card-text>
      </base-card>
    </v-form>
  </v-col>
</template>

<script>

export default {
  name: 'ConfigBillingDate',

  data() {
    return {
      data: {
        billingDateConfigDays: '',
        configStatus: false,
      },
      searchIfIsAdmin: false,
      search: '',
      errorMessages: '',
      formHasErrors: null,
      successAlert: false,
      msgSuccessAlert: '',
      errorAlert: false,
      msgErrorAlert: '',
      formRules: [
        (v) => !!v || 'Este campo es requerido (Solo acepta números)',
        (v) => v > 0 || 'El valor del campo debe ser mayor a cero',
        (v) => v < 60 || 'El valor del campo debe ser menor a 60 días',
      ],
      companySelectorError: false,
      helperSelectorText: '',
      firstTime: true,
    }
  },

  computed: {
    statusResponseUpdateBillingDateConfig() {
      return this.$store.state.billingDate.statusSetBillingConfResponse
    },
    statusResponseGetBillingDateConfig() {
      return this.$store.state.billingDate.statusGetBillingConfResponse
    },
    detailClients() {
      if (this.isAdmin) {
        return (
          this.$store.state.billingDate.listClients.Mensaje || []
        )
          .filter((compania) => compania.Activo === 1)
          .filter((compania) =>
            compania.NombreCompania.toLowerCase().includes(
              this.search.toLowerCase()
            )
          )
          .map((compania) => ({
            ...compania,
            text: `[${compania.IdCompania}] ${compania.NombreCompania}`,
          }));
      }

      return (this.$store.state.billingDate.listClients.Mensaje || []).filter(
        (compania) =>
          compania.IdCompania ===
          JSON.parse(atob(localStorage.getItem("uid"))).idcompania
      );
    },
  },

  created () {
    this.isAdmin =
      JSON.parse(atob(localStorage.getItem("uid"))).rol === "Administrador"
    if(!this.isAdmin) {
      this.getBillingDateConfig()
      this.searchIfIsAdmin = true
    }
    this.getListClients()
  },

  watch: {
    '$store.state.billingDate.billingDateConfigData': {
      handler(data) {
        this.data.billingDateConfigDays = data.Dias
        this.data.configStatus = data.CambioAutomatico === '1'
      },
      deep: true
    },
    formHasErrors () {
      if (this.formHasErrors === false) {
        this.sendBillingDateConfig()
        this.formHasErrors = null
      }
    },
    statusResponseUpdateBillingDateConfig () {
      if(this.statusResponseUpdateBillingDateConfig === false) {
        this.generateAlertSuccess()
        this.formHasErrors = true
        this.msgSuccessAlert = this.$store.state.billingDate.billingDateConfigMessage
        this.$store.dispatch('billingDate/setUpdateStatusResponse', null)
      } else if(this.statusResponseUpdateBillingDateConfig === true) {
        this.generateAlertError()
        this.formHasErrors = true
        this.msgSuccessAlert = this.$store.state.billingDate.billingDateConfigMessage
        this.$store.dispatch('billingDate/setUpdateStatusResponse', null)
      }
    },
    statusResponseGetBillingDateConfig () {
      if(this.statusResponseGetBillingDateConfig === false) {
        this.searchIfIsAdmin = true
        this.generateAlertSuccess()
        this.msgSuccessAlert = this.$store.state.billingDate.billingDateConfigMessage
        this.$store.dispatch('billingDate/statusGetBillingConfResponse', null)
      } else if(this.statusResponseGetBillingDateConfig === true) {
        this.searchIfIsAdmin = true
        this.generateAlertError()
        this.msgSuccessAlert = this.$store.state.billingDate.billingDateConfigMessage
        this.$store.dispatch('billingDate/statusGetBillingConfResponse', null)
      }
    }
  },

  methods: {
    async getListClients () {
      await this.$store.dispatch('billingDate/getListClients')
    },
    async getBillingDateConfig () {
      await this.$store.dispatch('billingDate/getBillingDateConfigData', {
        ...this.data
      })
    },
    generateAlertSuccess () {
      this.successAlert = true
      setTimeout(() => {
        this.successAlert = false
      }, 3000)
    },
    generateAlertError () {
      this.errorAlert = true
      setTimeout(() => {
        this.errorAlert = false
      }, 3000)
    },
    validateConfigData () {
      this.formHasErrors = !this.$refs.billingDateForm.validate();
    },
    sendBillingDateConfig () {
      this.$store.dispatch('billingDate/updateBillingDateConfigData', {
        ...this.data
      })
    },
    validateCompanyField(value) {
      if ((!value || value <= 0) && this.firstTime === false) {
        this.companySelectorError = true
        this.helperSelectorText = 'El campo no puede estar vacío.'
        return false
      } else {
        this.companySelectorError = false
        this.helperSelectorText = ''
        return true
      }
    },
    checkCompanyField() {
      this.firstTime = false
      if (this.validateCompanyField(this.data.IdCompania)) {
        this.getBillingDateConfig()
      }
    },
    handleSelectChange (newValue) {
      this.searchIfIsAdmin = false
    }
  }
}

</script>
