<template>
  <div>
    <v-card>
      <v-toolbar flat class="red-title">
        <v-toolbar-title>
          Sincronización por rangos - {{ nombreCompania }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-dialog ref="dialog" v-model="modal" persistent width="300px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRange"
                    label="Rango de fechas"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :error="dateRangeError"
                    :error-messages="dateRangeErrorMessage"
                    :rules="[validationRules.required]"
                  />
                </template>

                <v-date-picker
                  v-model="dateRange"
                  range
                  :min="minDate"
                  :max="maxDate"
                  @change="validateDateRange"
                  :error="dateRangeError"
                  :error-messages="dateRangeErrorMessage"
                >
                  <v-spacer />
                  <v-btn
                    text
                    style="background-color: rgb(204, 33, 40); color: white"
                    @click="onCancel"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    style="background-color: rgb(204, 33, 40); color: white"
                    :disabled="dateRangeError"
                    @click="onAccept"
                  >
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="serie"
                label="Serie"
                prepend-icon="mdi-text-box"
                required
                :error="!!fieldErrors.serie"
                :error-messages="fieldErrors.serie"
                :rules="[
                  validationRules.required,
                  validationRules.alphanumeric,
                ]"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-subheader class="section-title"
                >Rangos de documentos</v-subheader
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="d-flex align-center">
              <span class="mr-2">Rango de documentos (Inicio)</span>
              <v-text-field
                v-model="documentRangeStart"
                prepend-icon="mdi-file-document"
                type="number"
                required
                :error="!!fieldErrors.documentRangeStart"
                :error-messages="fieldErrors.documentRangeStart"
                :rules="[
                  validationRules.rangeStartEnd,
                  validationRules.rangeDocument,
                ]"
                @blur="documentRangeStart = Number(documentRangeStart)"
              />
            </v-col>

            <v-col cols="12" sm="6" class="d-flex align-center">
              <span class="mr-2">Rango de documentos (Final)</span>
              <v-text-field
                v-model="documentRangeEnd"
                prepend-icon="mdi-file-document"
                type="number"
                required
                :error="!!fieldErrors.documentRangeEnd"
                :error-messages="fieldErrors.documentRangeEnd"
                :rules="[
                  validationRules.rangeStartEnd,
                  validationRules.rangeStartBeforeEndDocument,
                ]"
                @blur="documentRangeEnd = Number(documentRangeEnd)"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-subheader class="section-title"
                >Rangos de resolución</v-subheader
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="6" class="d-flex align-center">
              <span class="mr-2">Rango de resolución (Inicio)</span>
              <v-text-field
                v-model="resolutionRangeStart"
                prepend-icon="mdi-note-edit-outline"
                type="number"
                required
                :error="!!fieldErrors.resolutionRangeStart"
                :error-messages="fieldErrors.resolutionRangeStart"
                :rules="[validationRules.rangeStartEnd]"
                @blur="resolutionRangeStart = Number(resolutionRangeStart)"
              />
            </v-col>

            <v-col cols="12" sm="6" class="d-flex align-center">
              <span class="mr-2">Rango de resolución (Final)</span>
              <v-text-field
                v-model="resolutionRangeEnd"
                prepend-icon="mdi-note-edit-outline"
                type="number"
                required
                :error="!!fieldErrors.resolutionRangeEnd"
                :error-messages="fieldErrors.resolutionRangeEnd"
                :rules="[
                  validationRules.rangeStartEnd,
                  validationRules.rangeStartBeforeEnd,
                ]"
                @blur="resolutionRangeEnd = Number(resolutionRangeEnd)"
              />
            </v-col>
          </v-row>

          <v-card-actions class="justify-end">
            <v-btn
              text
              style="background-color: rgb(204, 33, 40); color: white"
              @click="synchronize"
              :disabled="generating"
            >
              Sincronizar
            </v-btn>

            <v-btn text color="gray" @click="clearForm" :disabled="generating">
              Limpiar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
      <v-dialog
        v-model="responseModal.show"
        transition="dialog-bottom-transition"
        max-width="1000"
      >
        <v-card>
          <v-toolbar color="primary" dark>
            Sincronización de la factura - Detalles
          </v-toolbar>
          <v-card-text>
            <v-row class="fill-height" align-content="center" justify="center">
              <div
                class="text-h6 pa-4"
                style="width: 90%; max-height: 400px; overflow-y: auto"
              >
                <json-viewer
                  v-if="responseModal.data"
                  :value="responseModal.data"
                  copyable="true"
                >
                  <template v-slot:copy>
                    Copiar
                    <v-icon
                      style="cursor: pointer"
                      color="info darken-3"
                      class="mr-2"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                </json-viewer>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="closeResponseModal">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "SynchronizeRanks",

  props: {
    nombreCompania: {
      type: String,
      required: true,
    },
    idCompania: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      generating: false,
      dateRange: [],
      serie: null,
      documentRangeStart: null,
      documentRangeEnd: null,
      resolutionRangeStart: null,
      resolutionRangeEnd: null,
      dateRangeError: false,
      dateRangeErrorMessage: "",
      fieldErrors: {
        serie: null,
        documentRangeStart: null,
        documentRangeEnd: null,
        resolutionRangeStart: null,
        resolutionRangeEnd: null,
      },
      responseModal: {
        show: false,
        data: null,
      },
      minDate: null,
      maxDate: this.getTodayDate(),
    };
  },

  computed: {
    minDate() {
      const today = new Date();
      today.setDate(today.getDate() - 30);
      return today.toISOString().split("T")[0];
    },
    maxDate() {
      const today = new Date();
      return today.toISOString().split("T")[0];
    },
    validationRules() {
      return {
        required: (v) => !!v || "Este campo es obligatorio",
        alphanumeric: (v) =>
          /^[a-zA-Z0-9]*$/.test(v) || "Solo se permiten letras y números",
        number: (v) => !isNaN(v) || "Solo se permiten números",
        rangeStartEnd: (v) => v >= 1 || "Debe ser mayor o igual a 1",
        rangeStartBeforeEndDocument: (v) =>
          this.documentRangeEnd >= this.documentRangeStart ||
          "El rango final no puede ser menor al rango inicial",
        rangeStartBeforeEnd: (v) =>
          this.resolutionRangeEnd >= this.resolutionRangeStart ||
          "El rango final no puede ser menor al rango inicial",
      };
    },
  },

  watch: {
    dateRange(newRange) {
      if (newRange.length > 0) {
        this.minDate = this.calculateMinDate(newRange[0]);
      } else {
        this.minDate = null;
      }
    },
  },

  methods: {
    getTodayDate() {
      const today = new Date();
      return today.toISOString().split("T")[0];
    },

    calculateMinDate(selectedDate) {
      const selected = new Date(selectedDate);
      selected.setDate(selected.getDate() - 30);
      return selected.toISOString().split("T")[0];
    },

    validateDateRange(dateRange) {
      if (dateRange.length === 2) {
        const startDate = new Date(dateRange[0]);
        const endDate = new Date(dateRange[1]);

        if (startDate > endDate) {
          this.dateRangeError = true;
          this.dateRangeErrorMessage =
            "La fecha de inicio no puede ser mayor que la fecha de fin.";
        } else {
          this.dateRangeError = false;
          this.dateRangeErrorMessage = "";
        }
      }
    },

    validateForm() {
      this.fieldErrors = {
        serie: null,
        documentRangeStart: null,
        documentRangeEnd: null,
        resolutionRangeStart: null,
        resolutionRangeEnd: null,
      };

      let valid = true;

      if (!this.serie) {
        this.fieldErrors.serie = "El campo es obligatorio.";
        valid = false;
      }
      if (!this.documentRangeStart) {
        this.fieldErrors.documentRangeStart = "El campo es obligatorio.";
        valid = false;
      }
      if (!this.documentRangeEnd) {
        this.fieldErrors.documentRangeEnd = "El campo es obligatorio.";
        valid = false;
      }
      if (!this.resolutionRangeStart) {
        this.fieldErrors.resolutionRangeStart = "El campo es obligatorio.";
        valid = false;
      }
      if (!this.resolutionRangeEnd) {
        this.fieldErrors.resolutionRangeEnd = "El campo es obligatorio.";
        valid = false;
      }
      if (!this.dateRange || this.dateRange.length !== 2) {
        this.dateRangeError = true;
        this.dateRangeErrorMessage =
          "Debe seleccionar un rango de fechas válido.";
        valid = false;
      } else {
        this.dateRangeError = false;
        this.dateRangeErrorMessage = "";
      }

      return valid;
    },

    async synchronize() {
      if (!this.validateForm()) {
        return;
      }

      const data = {
        serie: this.serie,
        IdCompania: this.idCompania,
        fecha: this.dateRange[0],
        fechaFin: this.dateRange[1],
        documentRangeStart: this.documentRangeStart,
        documentRangeEnd: this.documentRangeEnd,
        resolutionRangeStart: this.resolutionRangeStart,
        resolutionRangeEnd: this.resolutionRangeEnd,
      };

      try {
        this.generating = true;
        const response = await this.$store.dispatch(
          "invoice/sincronizarPorRangos",
          data
        );

        this.showResponseModal(response);
      } catch (error) {
        console.error("Error al sincronizar:", error);
        this.showResponseModal({
          error: true,
          message: "Error en la sincronización",
        });
      } finally {
        this.generating = false;
      }
    },
    close() {
      this.clearForm();
      this.$refs.form.resetValidation();
      this.$emit("close");
    },
    clearForm() {
      this.dateRange = [];
      this.serie = null;
      this.documentRangeStart = null;
      this.documentRangeEnd = null;
      this.resolutionRangeStart = null;
      this.resolutionRangeEnd = null;
      this.dateRangeError = null;
      this.dateRangeErrorMessage = "";
      this.fieldErrors = {
        serie: null,
        documentRangeStart: null,
        documentRangeEnd: null,
        resolutionRangeStart: null,
        resolutionRangeEnd: null,
      };
      this.$refs.form.resetValidation();
    },
    onAccept() {
      if (!this.dateRangeError && this.dateRange.length === 2) {
        this.modal = false;
      } else {
        this.dateRangeError = true;
        this.dateRangeErrorMessage =
          "Debe seleccionar ambas fechas en el rango.";
      }
    },
    closeResponseModal() {
      this.responseModal.show = false;
      this.responseModal.data = null;
    },
    showResponseModal(data) {
      this.responseModal.data = data;
      this.responseModal.show = true;
    },
    onCancel() {
      this.dateRange = [];
      this.modal = false;
    },
  },
};
</script>
<style scoped>
.red-title {
  background-color: rgb(204, 33, 40) !important;
  color: white !important;
}

.section-title {
  font-size: 24px;
  font-weight: normal;
  color: #ffffff;
}
</style>
