<template>
  <v-row>
    <v-col
      cols="12"
    >
      <v-alert
        v-show="alertStatus"
        type="success"
        text
      >
        {{ msgAlertStatus }}
      </v-alert>
      <base-card>
        <v-card-text>
          <v-card-title>
            Listados Clientes FE
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="details"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.Estado`]="{ item }">
              <v-icon
                v-if="item.Estado === 'H'"
                color="yellow darken-2"
                right
                class="mr-2"
              >
                mdi-alpha-h-circle
              </v-icon>
              <v-icon
                v-else-if="item.Estado === 'P'"
                color="success darken-2"
                right
                class="mr-2"
              >
                mdi-alpha-p-circle
              </v-icon>

              <v-icon
                v-else
                color="info darken-2"
                right
                class="mr-2"
              >
                mdi-alpha-d-circle
              </v-icon>
            </template>
            <template v-slot:[`item.Activo`]="{ item }">
              <v-icon
                v-if="item.Activo === 1"
                color="success darken-2"
                right
                class="mr-2"
              >
                mdi-account-check
              </v-icon>
              <v-icon
                v-else
                color="warning darken-2"
                right
                class="mr-2"
              >
                mdi-account-cancel
              </v-icon>
            </template>
            <template v-slot:[`item.TokenEnterprise`]="{ item }">
              <TokenCopyIcon
                :token="item.TokenEnterprise"
                @open-dialog="openTokenDialog('Enterprise', item.TokenEnterprise)"
              />
            </template>
            <template v-slot:[`item.TokenPassword`]="{ item }">
              <TokenCopyIcon
                :token="item.TokenPassword"
                @open-dialog="openTokenDialog('Password', item.TokenPassword)"
              />
            </template>
            <template v-slot:[`item.Nit`]="{ item }">
              <v-dialog
                  max-width="1000"
                  transition="dialog-bottom-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      class="mr-2"
                      color="info darken-3"
                      style="cursor:pointer;"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-information-box
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                        dark
                        style="background-color:rgb(204 33 40);color:white !important"
                    >
                      DATOS FABRICANTE/DISTRIBUIDOR - CLIENTE [{{ item.NombreCompania }}]

                      <v-icon
                          style="margin-left: 460px !important;"
                          @click="dialog.value = false"
                      >mdi-close
                      </v-icon>

                    </v-toolbar>

                    <campo-client
                        :token_enterprise=item.TokenEnterprise
                        :token_password=item.TokenPassword
                        :fabricante=item.FabricanteSoftware
                        :nit=item.Nit
                        :razon_social=item.RazonSocialDistribuidor
                        :distribuidor=item.Distribuidor
                    />

                  </v-card>

                </template>
              </v-dialog>
            </template>
            <template v-slot:[`item.EmailFE`]="{ item }">
              <v-dialog max-width="500" transition="dialog-bottom-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="warning darken-3"
                    style="cursor:pointer;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                       {{ item.NombreCompania }}
                       <v-btn icon class="ml-auto" @click="dialog.value = false">
                         <v-icon>mdi-close</v-icon>
                       </v-btn>
                    </v-toolbar>

                    <campo-client-email-facturacion
                      :token_enterprise=item.TokenEnterprise
                      :token_password=item.TokenPassword
                      :email=item.EmailFE
                      emailType="EmailFE"
                      @email-updated="onEmailUpdated"
                    />

                  </v-card>

                </template>
              </v-dialog>
            </template>

            <template v-slot:[`item.EmailDelivery`]="{ item }">
              <v-dialog max-width="500" transition="dialog-bottom-transition">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="green darken-2"
                    style="cursor:pointer;"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      dark
                      style="background-color:rgb(204 33 40);color:white !important"
                    >
                       {{ item.NombreCompania }}
                       <v-btn icon class="ml-auto" @click="dialog.value = false">
                         <v-icon>mdi-close</v-icon>
                       </v-btn>
                    </v-toolbar>

                    <campo-client-email-facturacion
                      :token_enterprise=item.TokenEnterprise
                      :token_password=item.TokenPassword
                      :email=item.EmailDelivery
                      emailType="EmailDelivery"
                      @email-updated="onEmailUpdated"
                    />

                  </v-card>

                </template>
              </v-dialog>
            </template>

            <template v-slot:[`item.InfDocProcesados`]="{ item }">
              <v-icon
                class="mr-2"
                color="blue darken-1"
                style="cursor:pointer;"
                @click="openReportDialog(item)"
              >
                mdi-file-download
              </v-icon>
            </template>

            <template v-slot:[`item.InfDocPendientes`]="{ item }">
              <v-icon
                class="mr-2"
                color="orange darken-3"
                style="cursor:pointer;"
                @click="openPendingReportDialog(item)"
              >
                mdi-file-download-outline
              </v-icon>
            </template>

            <template v-slot:[`item.sincronizarRangos`]="{ item }">
              <v-row justify="center">
                <v-btn color="blue" small @click="openSyncDialog(item)" icon>
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </v-row>
            </template>

            <template v-slot:[`item.ActivarInactivar`]="{ item }" >
              <v-icon
                v-if="item.Activo == 1"
                style="cursor:pointer;text-align-last: center;"
                color="red darken-3"
                class="mr-2"
                @click="activeInactiveClients(item)"
              >
                mdi-account-cancel
              </v-icon>
              <v-icon
                v-else
                style="cursor:pointer;"
                color="success darken-3"
                class="mr-2"
                @click="activeInactiveClients(item)"
              >
                mdi-account-check
              </v-icon>
            </template>

            <template v-slot:[`item.Editar`]="{ item }">
              <v-icon
                style="cursor:pointer;"
                color="primary"
                @click="openUpdateClientDialog(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
      <v-dialog v-model="dialogUpdateClient" max-width="600px">
        <show-update-clients
          v-model="dialogUpdateClient"
          :client="selectedClient"
          :type-environment="typeEnvironment"
          :list-technology-provider="listTechnologyProvider"
          :emisor_client_Id="emisor_client_Id"
          :client_id="client_id"
          :client_secret="client_secret"
          :grant_type="grant_type"
          :scope="scope"
          :url_token="url_token"
          :representation_graphic="representation_graphic"
          :id-compania="selectedClientIdCompania"
          @client-updated="onClientUpdated"
        />
      </v-dialog>
      <v-dialog v-model="tokenDialog" max-width="500" transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar dark style="background-color: rgb(204, 33, 40); color: white !important;">
            {{ tokenTitle }}
            <v-icon style="margin-left: auto; cursor: pointer;" @click="closeTokenDialog">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <div style="text-align: center; margin-top: 20px;">
              <p style="margin-bottom: 20px; font-size: 16px;">{{ selectedToken }}</p>
              <v-btn
                @click="copyToken"
                color="primary"
                elevation="2"
                class="ma-2"
              >
                Copiar Token
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="reportDialog"  max-width="700" transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar
            dark
            style="background-color:rgb(204 33 40);color:white !important"
          >
            {{ nombreCompania }} - Generador de informe de documentos procesados
            <v-btn icon class="ml-auto" @click="reportDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <processed-docs-filter
            :id_compania = idCompania
            :nombre_compania = nombreCompania
            :report_dialog="reportDialog"
          />

        </v-card>
      </v-dialog>

      <v-dialog v-model="pendingReportDialog"  max-width="700" transition="dialog-bottom-transition">
        <v-card>
          <v-toolbar
            dark
            style="background-color:rgb(204 33 40);color:white !important"
          >
            {{ nombreCompania }} - Generador de informe de documentos pendientes
            <v-btn icon class="ml-auto" @click="pendingReportDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <pending-docs-filter
            :id_compania = idCompania
            :nombre_compania = nombreCompania
            :pending_report_dialog="pendingReportDialog"
          />

        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showSyncDialog"
        max-width="600px"
        @input="handleDialogInput"
      >
        <synchronize-ranks
          ref="synchronizeRanks"
          @close="closeSyncDialog"
          :nombreCompania="selectedItem.NombreCompania"
          :idCompania="selectedItem.IdCompania"
        />
      </v-dialog>

      <v-snackbar v-model="snackbar" timeout="3000" top color="green">
        {{ snackbarMessage }}
      </v-snackbar>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    components: {
      ProcessedDocsFilter: () => import('./ProcessedDocsFilter.vue'),
      PendingDocsFilter: () => import('./PendingDocsFilter.vue'),
      CampoClient: () => import('./CampoClient'),
      CampoClientEmailFacturacion: () => import('./CampoClientEmailFacturacion'),
      ShowUpdateClients: () => import('./ShowUpdateClients.vue'),
      TokenCopyIcon: () => import('./TokenCopyIcon.vue'),
      SynchronizeRanks: () => import('./SynchronizeRanks.vue'),
    },
    name: 'ShowClients',
    data () {
      return {
        search: '',
        copyAlert: false,
        msgAlertStatus: 'Estado de cliente modificado',
        alertStatus: false,
        dialogUpdateClient: false,
        reportDialog: false,
        pendingReportDialog: false,
        selectedClient: null,
        emisor_client_Id: '',
        client_id: '',
        client_secret: '',
        grant_type: '',
        scope: '',
        url_token: '',
        processed_docs_data: [],
        representation_graphic: [],
        tokenDialog: false,
        selectedToken: "",
        snackbar: false,
        snackbarMessage: "",
        tokenTitle: '',
        idCompania: null,
        nombreCompania: null,
        headers: [
          { text: 'Editar', value: 'Editar', align: 'center', sortable: false },
          { text: 'Compania', value: 'IdCompania' },
          { text: 'Cliente', value: 'NombreCompania' },
          { text: 'Nit', value: 'NitCliente' },
          { text: 'Ambiente', value: 'Estado' },
          { text: 'Estado', value: 'Activo' },
          { text: 'Datos Fabricante', value: 'Nit' , align: 'center', sortable: false,},
          { text: 'Email Inf.FE', value: 'EmailFE' , align: 'center', sortable: false,},
          { text: 'Email Delivery', value: 'EmailDelivery', align: 'center', sortable: false },
          { text: 'Inf. Doc. Procesados', value: 'InfDocProcesados', align: 'center', sortable: false },
          { text: 'Inf. Doc. Pendientes', value: 'InfDocPendientes', align: 'center', sortable: false },
          { text: 'Sincronizar Rangos Numeración', value: 'sincronizarRangos', align: 'center', sortable: false },
          { text: 'Token Enterprise', align: 'center', sortable: false, value: 'TokenEnterprise' },
          { text: 'Token Password', align: 'center', sortable: false, value: 'TokenPassword' },
          { text: 'Url Proveedor Tecnológico', value: 'Url' },
          { text: 'Url Api Gestión', value: 'UrlApiGestion' },
          { text: 'Fecha Creación   ', value: 'FechaCreacion' },
          { text: 'Fecha Actualización', value: 'FechaActualizacion' },
          { text: 'Activar / Inactivar', value: 'ActivarInactivar' },
        ],
        typeEnvironment: [
          { textEnvironment: "Producción", valueEnvironment: "P" },
          { textEnvironment: "Desarrollo", valueEnvironment: "D" },
          { textEnvironment: "Habilitación", valueEnvironment: "H" },
        ],
        selectedItem: {},
        showSyncDialog: false,
      }
    },
    computed: {
      details () {
        return this.$store.state.clients.listClients.Mensaje
      },
      updateResponseClient () {
        return this.$store.state.clients.updateStatusResponse
      },

    },
    watch: {
      updateResponseClient () {
        if (this.updateResponseClient > 0) {
          this.generateAlertStatus()
          this.$store.dispatch('clients/setUpdateResponseClient', 0)
        }
      },
    },
    created () {
      this.getListClients()
    },
    methods: {
      generateAlertStatus () {
        this.alertStatus = true
        this.$store.dispatch('clients/getListClients')
        setTimeout(() => {
          this.alertStatus = false
        }, 3000)
      },
      copyToken() {
      navigator.clipboard.writeText(this.selectedToken)
      this.closeTokenDialog();
      this.snackbarMessage = "Token copiado exitosamente!";
      this.snackbar = true;
    },
      generateAlertCopy () {
        this.copyAlert = true
        setTimeout(() => {
          this.copyAlert = false
        }, 2000)
      },
      async getListClients () {
        await this.$store.dispatch('clients/getListClients')
      },
      async activeInactiveClients (item) {
        await this.$store.dispatch('clients/activeInactiveClient', item)
      },
      openTokenDialog(tokenType, token) {
        this.selectedToken = token;
        this.tokenDialog = true;
        this.tokenTitle = tokenType === 'Enterprise' ? 'Token Enterprise' : 'Token Password';
      },
      closeTokenDialog() {
        this.tokenDialog = false;
      },
      openReportDialog(item) {
        this.idCompania = item.IdCompania;
        this.nombreCompania = item.NombreCompania;
        this.reportDialog = true
      },

      openPendingReportDialog(item) {
        this.idCompania = item.IdCompania;
        this.nombreCompania = item.NombreCompania;
        this.pendingReportDialog = true
      },


      openUpdateClientDialog(item) {

      try {
        const informacionAdicional = JSON.parse(item.InformacionAdicional);

        this.emisor_client_Id = informacionAdicional.emisor_client_Id || "";
        this.client_id = informacionAdicional.client_id || "";
        this.client_secret = informacionAdicional.client_secret || "";
        this.grant_type = informacionAdicional.grant_type || "";
        this.scope = informacionAdicional.scope || "";
        this.url_token = informacionAdicional.url_token || "";
        this.representation_graphic = informacionAdicional.representation_graphic || [];

      } catch (error) {
        console.error("Error al parsear InformacionAdicional:", error);
        this.emisor = "";
        this.client_id = "";
        this.client_secret = "";
        this.grant_type = "";
        this.scope = "";
        this.url_token = "";
      }

      this.listTechnologyProvider = [
        { textEnvironment: "EDN", valueEnvironment: "EDN" },
        { textEnvironment: "HKA", valueEnvironment: "HKA" },
      ];

      if (item.UrlApiGestion && item.UrlApiGestion.includes("URL_EDN")) {
        item.Proveedor = "EDN";
      } else {
        item.Proveedor = "HKA";
      }
      this.selectedClient = item;
      this.selectedClientIdCompania = item.IdCompania;
      this.dialogUpdateClient = true;
    },

    onClientUpdated() {
      this.getListClients();
    },

    onEmailUpdated() {
      this.getListClients();
    },

    openSyncDialog(item) {
      this.selectedItem = {
        NombreCompania: item.NombreCompania,
        IdCompania: item.IdCompania,
      };
      this.showSyncDialog = true;
    },
    closeSyncDialog() {
      this.$refs.synchronizeRanks.clearForm();
      this.showSyncDialog = false;
      this.selectedItem = {};
    },
    handleDialogInput(value) {
      if (!value) {
        this.closeSyncDialog();
      }
    },
  },
}
</script>

<style scoped>

</style>
