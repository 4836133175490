import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusGetBillingConfResponse: null,
    statusSetBillingConfResponse: null,
    billingDateConfigData: [],
    billingDateConfigMessage: '',
    listClients: [],
  },
  mutations: {
    setListClients (state, data) {
      state.listClients = data
    },
    setBillingDateConfigData (state, data) {
      state.billingDateConfigData = data
    },
    setResponseUpdateBillingDateConfig (state, data) {
      state.statusSetBillingConfResponse = data
    },
    setResponseGetBillingDateConfig (state, data) {
      state.statusGetBillingConfResponse = data
    },
    setResponseBillingDateConfigMsg (state, data) {
      state.billingDateConfigMessage = data
    },
  },
  actions: {
    getListClients ({ commit }) {
      const endpoint = '/api/getClients'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListClients', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getBillingDateConfigData ({ commit }, data) {
      let params = 'IdCompania=' + -1
      if (JSON.parse(atob(localStorage.getItem('uid'))).rol !== 'Administrador') {
        params = 'IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania
      } else {
        params = 'IdCompania=' + data.IdCompania
      }

      const endpoint = '/api/getBillingDateConfigData?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url)
        .then(response => {
            commit('setBillingDateConfigData', response.data.Data)
          if (response.data.Error) {
            commit('setResponseGetBillingDateConfig', true)
            commit('setResponseBillingDateConfigMsg', 'Hubo un error obteniendo la información de la configuración de cambio de fecha en facturas. Intentelo nuevamente.')
          } else {
            commit('setResponseGetBillingDateConfig', false)
            commit('setResponseBillingDateConfigMsg', 'Información de la configuración de cambio de fecha en facturas consultada exitosxamente.')
          }
        })
        .catch(function (error) {
          commit('setResponseGetBillingDateConfig', true)
          commit('setResponseBillingDateConfigMsg', 'No pudo obtener información de la configuraicón de cambio de fecha en facturas. Intentelo nuevamente.')
          console.log(error.message)
        })
    },
    updateBillingDateConfigData ({ commit }, data) {
      const endpoint = '/api/updateBillingDateConfigData'
      const url = environment.HIOPOSWEBSERVICES + endpoint

      if (JSON.parse(atob(localStorage.getItem('uid'))).rol !== 'Administrador') {
        data.IdCompania = JSON.parse(atob(localStorage.getItem('uid'))).idcompania
      }

      axios
        .create()
        .put(url, data)
        .then(response => {
          console.log(response)
            if (response.data.Error) {
              commit('setResponseUpdateBillingDateConfig', true)
              commit('setResponseBillingDateConfigMsg', 'Hubo un error guardando la información de cambio de fecha en facturas. Intentelo nuevamente.')
            } else {
              commit('setResponseUpdateBillingDateConfig', false)
              commit('setResponseBillingDateConfigMsg', 'Información de la configuración de cambio de fecha en facturas guardada exitosxamente.')
            }
        })
        .catch(function (error) {
          commit('setResponseUpdateBillingDateConfig', true)
          commit('setResponseBillingDateConfigMsg', 'No pudo obtener información de la configuraicón de cambio de fecha en facturas')
          console.log(error.message)
        })
    },
    setUpdateStatusResponse ({ commit }, data) {
      commit('setResponseUpdateBillingDateConfig', data)
    },
    statusGetBillingConfResponse ({ commit }, data) {
      commit('setResponseGetBillingDateConfig', data)
    },
  },
}
