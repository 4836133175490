<template>
  <div>
    <processed-docs ref="ProcessedDocs" />
    <pending-docs ref="PendingDocs" />
    <config-billing-date ref="ConfigBillingDate" />
    <config-alerts-folios ref="ConfigAlertsFolios" />
    <config-alerts-certificate ref="ConfigAlertsCertificate" />
  </div>
</template>

<script>
  export default {
    components: {
      ProcessedDocs: () => import('./components/ProcessedDocs'),
      PendingDocs: () => import('./components/PendingDocs'),
      ConfigBillingDate: () => import('./components/BillingDateConfigs'),
      ConfigAlertsFolios: () => import('./components/FoliosConfigs'),
      ConfigAlertsCertificate: () => import('./components/CertificateConfigs'),
    },
  }
</script>
